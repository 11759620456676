const ESSENTIAL_FIELDS = {
    entryType: true,
    clientType: true,
    dns: true,
    tcp: true,
    ssl: true,
    ttfb: true,
    _brandId: true,
    viewerName: true,
    v: true,
    dc: true,
    microPop: true,
    cdn: true,
    msid: true,
    pageId: true,
    fp: true,
    fcp: true,
    tti: true,
    tbt: true,
    lcp: true,
    cls: true,
    ttlb: true,
    dcl: true,
    isSsr: true,
    ssrCaching: true,
    ssrDuration: true,
    startTime: true,
    duration: true,
    delay: true,
    action: true,
    type: true,
    pn: true
};

/**
 * Apply consent policy restrictions
 * @param {Window} window 
 * @param {Object} measurement 
 */
export default function applyConsent({consentPolicyManager}, measurement) {
    if (Symbol.iterator in measurement) {
        return measurement;
    }
    
    const analytics = consentPolicyManager?.getCurrentConsentPolicy()?.policy?.analytics;
    if (analytics !== false) {
        return measurement;
    }

    return Object.entries(measurement)
        .filter(isEssentialField)
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});
}

function isEssentialField([key]) {
    return ESSENTIAL_FIELDS[key];
}
